import React from 'react';
import { Post } from './Post';

export class PostContainer extends React.Component {
    constructor() {
        super();
        this.state = { data: [] };
    }

    componentDidMount() {
        const reqURL = new URL('https://worker.djavaisadog.workers.dev/posts');

        return fetch(reqURL).then(i => i.json()).then(json => this.setState({ data: json }));;
    }

    render() {
        return ([(<div className="post-top-spacer"></div>)].concat(
            this.state.data.map(i => (
            <div>
                <Post title={i.title} author={i.username} content={i.content} />
                <div className="post-top-spacer"></div>
            </div>
        ))));
    }

}
