import './App.css';
import { PostContainer } from './components/PostContainer';


function App() {
    return (
        <div className="app-container">
            <div className="posts-container">
                <PostContainer />
            </div>
        </div>
    );
}

export default App;
