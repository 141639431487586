import React from 'react';

export class Post extends React.Component {
    render() {
        const comp =  (
            <div className="single-post">
                <div className="post-header">
                    <span className="post-title">{this.props.title}</span>
                    <br />
                    <span className="post-author">By: {this.props.author}</span>
                </div>
                <div className="post-content">{this.props.content}</div>
            </div>
        );

        return comp;
    }

    
}
